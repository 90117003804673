<template>
    <div>
      <el-dialog
          title="请确认是否要重新发送以下VGM数据吗？"
          :visible.sync="reSendDialogVisible"
          width="30%"
          append-to-body
          center
      >
      <div class="remind">提示：重新发送，一个箱号需要收取5火币</div>
        <el-table :data="reSendData" style="width: 100%" stripe border
                  :header-cell-style="{background: '#eef1f6',color: '#606278'}"
        >
          <el-table-column
              prop="billNo"
              label="提单号"
          ></el-table-column>
          <el-table-column
              prop="vessel"
              label="船名"
          ></el-table-column>
          <el-table-column prop="voyage" label="航次" width="120"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
                  <el-button @click="reSendDialogVisible = false">
                      取 消
                  </el-button>
                  <el-button type="danger" @click="Delete">确认</el-button>
              </span>
      </el-dialog>
    </div>
  </template>

  <script>
  import {reSend} from "@/api/vgm.js";

  export default {
    data() {
      return {
        reSendDialogVisible: false,
        reSendData: [],
      };
    },
    methods: {
      init(list) {
        this.reSendDialogVisible = true;
        this.reSendData = list;
      },
      Delete() {
        const data = this.reSendData.map((x) => {
          return x.id;
        })
        reSend(data)
            .then((res) => {
              if (res.data.status) {
                this.$message.success("重新发送成功");
                this.$emit("refresh");
              } else {
                this.$message.error(res.data.message);
              }

            })
            .finally(() => {
              this.reSendDialogVisible = false;
            });
      },
    },
  };
  </script>

  <style lang="stylus" scoped>
    .remind{
        color: #007BFF;
    }
  </style>
